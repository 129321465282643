import { $map } from "foundation-ts/array";
import { $count, $isarray, $isstring, $isunsigned, $length, $ok, $tounsigned } from "foundation-ts/commons";
import { Nullable, UINT8_MAX, uint8 } from "foundation-ts/types";
import { $uuid } from "foundation-ts/crypto";
import { TSColor } from "foundation-ts/tscolor";
import { DocumentDto, SignatureDto } from "g1-commons/lib/doxecureClientTypes";
import { DocumentRepresentation } from "g1-commons/lib/doxecureTypes";
import { BCAPDF } from "bca-client-signature/lib/BCAPDF"
import { BCAAddSignaturesResults, BCAPDFSignatureItem } from "bca-client-signature/lib/BCAPDFSignatureZones";
import { makeSignaturesOptions } from "bca-client-signature/lib/BCAPDFFunctions";

import { getDocumentRepresentation } from "../services/session.service";

const getOriginalDocumentSourceInfos = async (document:DocumentDto|undefined|null, immutable:boolean, token?: Nullable<string>): Promise<[Nullable<DocumentRepresentation>, boolean]> => {
    if ($ok(document)) {
        if ($ok(document!.localSource)) { 
            return [{ pdfUrlData:document!.localSource! }, false] ;
        }
        if ($ok(document!.apid) && $ok(document!.session)) {
            const ret = await getDocumentRepresentation(document!.session!, document!.apid!, token) ;
            if ($isstring(ret.pdfUrlData) && $length(ret.pdfUrlData as string)) { return [ret, immutable] }
        }
    }
    return [null, true] ;
}

/** 
 * if immutable, the system does not recalculate the signature boxes.
 * (we consider that signature boxes are already in place because 
 *  the document is ready to be signed)
*/
export const getDocumentSource = async (document:Nullable<DocumentDto>, immutable:boolean, lastPageLines:number, signatures:Nullable<SignatureDto[]>, token?: Nullable<string>): Promise<Nullable<DocumentRepresentation>> => {
    let original:Nullable<DocumentRepresentation> ;
    [original, immutable] = await getOriginalDocumentSourceInfos(document, immutable, token) ;
    const lines = $tounsigned(lastPageLines) ;
    //optlog(`--- lastPageLines        :${lastPageLines}`) ;
    //optlog(`--- immutableDocument    :${immutable}`) ;
    //optlog(`--- signatures parameters:${$inspect(signatures)}`) ;
    
    if ($ok(original) && !immutable && $isunsigned(lines, UINT8_MAX) && $count(signatures) > 0) {
        // ========== 
        // here we have a base64 PDF without any signature and we will add
        // the given signatures according to the number of lastPageLines 
        const pdf = await BCAPDF.fromURLData(original!.pdfUrlData) ;
        if ($ok(pdf)) {
            const graphicSignatures:BCAPDFSignatureItem[] = [];
            for (let s of signatures!) {
                graphicSignatures.push({
                    text:`${s.signer.firstName} ${s.signer.lastName}`,
                    identifier:$uuid()
                }) ;
            }
            // TODO: integrate number of lines of signature on last pages
            const signaturePrepOptions = makeSignaturesOptions(undefined, 9, lines) ; // font size is bigger than real here
            
            signaturePrepOptions.debug = false ;

            const res = await pdf!.addSignatures(
                graphicSignatures, 
                signaturePrepOptions
            ) ;
            if (!$isarray(res)) {
                const zones = (res as BCAAddSignaturesResults).addedZones ;
                const stabiloColor = TSColor.rgb('cyan').toAlpha(90 as uint8) ;
                for (let z of zones) {
                    pdf!.drawingPage = z.page ;
                    pdf!.drawRect(z.signatureRect, null, null, stabiloColor!) ;
                }    

                const s = await pdf!.toURLData() ;
                if ($ok(s)) { 
                    const signatures = $map(zones, (z) => z.signaturePosition) ;
                    return {
                        pdfUrlData:s!,
                        apid:original!.apid,
                        visibleSignatures:signatures
                    } ;
                }
            }
        }
    }
    return original ;
}