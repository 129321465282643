import React, { ReactNode } from "react";

import './ViewerPageContainer.css';

interface ViewerPageContainerProps {
    children: ReactNode ;
}

export const ViewerPageContainer = React.memo((props:ViewerPageContainerProps) => {
    const { children } = props;
    return <div className="bcaViewerPageContainerBox">{children}</div>;
}) ;

// import React, { CSSProperties, ReactNode } from "react";

// interface ViewerPageContainerProps {
//     children: ReactNode ;
//     style?:CSSProperties
// }

// export const ViewerPageContainer = React.memo((props:ViewerPageContainerProps) => {
//     const { children, style } = props;
//     const internalStyle = {
//         ...style,
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "center",
//         outline: "1px solid #ccc"
//     };
//     return <div style={internalStyle}>{children}</div>;
// }) ;
