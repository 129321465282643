import { HStack,Text, Textarea, VStack } from "@chakra-ui/react";
import React, { ChangeEvent, useState } from "react";

import { DXLengths } from "g1-commons/lib/doxecureModelTypes";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { AsyncActionModal } from "./AsyncActionModal";
import { ModalWindowState } from "./ModalConstants";

export const DefaultAsyncRefuseModalState:AsyncRefuseModalState = {
    isOpen: false,
    onAccept: (_reason: string) => {},
    onClose: async () => {},
} ;


export interface AsyncRefuseModalState extends ModalWindowState {
    isOpen: boolean;
    onAccept: (reason: string) => void;
}

export const AsyncRefuseModal = (props: AsyncRefuseModalState) => {
    const { isOpen, size = 'md', onClose, onAccept } = props ;
    const [nbCharacters, setNbCharacters] = useState(0) ;
    const [reason, setReason] = useState("") ;
    const maxCharacters = DXLengths.text ;

    const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setReason(e.target.value.replace(/\r?\n/g, " ")) ;
        setNbCharacters(e.target.value.length) ;
    }

    const handleClose = async () => {
        setReason("") ;
        setNbCharacters(0) ;
        await onClose() ;
    }

    const renderInputField = () => {
        return (
                <VStack spacing="0">
                    <Textarea
                        width="sm"
                        maxLength={maxCharacters}
                        bg={$g1Color('form.field.bg')}
                        color={$g1Color('form.field.write')}
                        borderColor={$g1Color('form.border')}
                        _hover={{ borderColor: $g1Color('form.field.hover.border') }}
                        _focus={{ borderColor: $g1Color('form.field.select.border') }}
                        _placeholder={{ color: $g1Color('form.field.placeholder') }}
                        onChange={onChange}>
                    </Textarea>
                    <HStack justify="flex-end" width="sm">
                        <Text fontSize="small">{nbCharacters}/{maxCharacters}</Text>
                    </HStack>
                </VStack>
        ) ;
    }

    return <AsyncActionModal title="Refus de signer" message="Veuillez indiquer ci-dessous la raison de votre refus." confirm="Refuser" cancel="Annuler" size={size} isOpen={isOpen} disabledConfirm={nbCharacters === 0} onClose={handleClose} onAccept={() => onAccept(reason)} element={renderInputField()} /> ;
} ;