import React, { useRef, useEffect } from "react";
import { PDFPageProxy } from "pdfjs-dist";

import { $ok } from "foundation-ts/commons";

import "./ViewerPage.css";

interface ViewerPageProps {
    page: PDFPageProxy;
    scale?: number
}

export const ViewerPage = React.memo((props: ViewerPageProps) => {
    const { page, scale = 1 } = props;

    const canvasRef = useRef(null) ;
    const textLayerRef = useRef(null) ;

    useEffect(() => {
        if (!page) {
            return;
        }
        const viewport = page.getViewport({ scale });

        // Prepare canvas using PDF page dimensions
        const canvas = canvasRef.current as any;
        if ($ok(canvas)) {
            const context = canvas.getContext("2d");
            if ($ok(context)) {
                canvas!.height = viewport.height ;
                canvas!.width = viewport.width ;
                
                const renderContext = {
                    canvasContext: context!,
                    viewport: viewport
                };
                const renderTask = page.render(renderContext);
                // Render PDF page into canvas context
                renderTask.promise.then(function () {
                    // console.log("Page rendered");
                });
            }
        }
    }, [page, scale]);


    return (
        <div className="ViewerPdfPage">
            <canvas ref={canvasRef} />
            <div ref={textLayerRef} className="ViewerPdfPageTextLayer" />
        </div>
    );
});
// import React, { useRef, useEffect } from "react";
// import { PDFPageProxy } from "pdfjs-dist";
// import { $ok } from "foundation-ts/commons";

// import "./ViewerPage.css";

// interface ViewerPageProps {
//     page: PDFPageProxy;
//     scale?: number
// }

// export const ViewerPage = React.memo((props: ViewerPageProps) => {
//     const { page, scale = 1 } = props;

//     const canvasRef = useRef(null) ;
//     const textLayerRef = useRef(null);

//     useEffect(() => {
//         if (!page) {
//             return;
//         }
//         const viewport = page.getViewport({ scale });

//         // Prepare canvas using PDF page dimensions
//         const canvas = canvasRef.current as any;
//         if ($ok(canvas)) {
//             const context = canvas.getContext("2d");
//             if ($ok(context)) {
//                 canvas!.height = viewport.height;
//                 canvas!.width = viewport.width;
//                 const renderContext = {
//                     canvasContext: context!,
//                     viewport: viewport
//                 };
//                 const renderTask = page.render(renderContext);
//                 // Render PDF page into canvas context
//                 renderTask.promise.then(function () {
//                     // console.log("Page rendered");
//                 });
//             }
//         }
//     }, [page, scale]);

//     return (
//         <div className="ViewerPdfPage">
//             <canvas ref={canvasRef} />
//             <div ref={textLayerRef} className="ViewerPdfPageTextLayer" />
//         </div>
//     );
// });
