import { Nullable, UUID } from "foundation-ts/types";
import { $email, $ok, $UUID } from "foundation-ts/commons";
import { TSInterval } from "foundation-ts/tsinterval";
import { TSDate } from "foundation-ts/tsdate";

import { SessionDto} from "g1-commons/lib/doxecureClientTypes";
import { SessionStatus } from "g1-commons/lib/doxecureModelTypes";

import { useQuery } from "react-query";

import { getOneSessionForUser } from "../services/session.service";
import { getUserProfile, minimalUserDto } from "../services/users.service";
import { SessionQueryID } from "./hooksConstants";


const emptyCreatorSession = async(userID: Nullable<UUID>): Promise<SessionDto> => {

    if (!$ok(userID)) { throw "Impossible to create a new session if we don't know the user identifier" ; }

    const creator = minimalUserDto(await getUserProfile(userID!)) ;
    const now = TSDate.zulu() ;
    return {
        title:"",
        creator:creator.apid!,
        initialCreator:creator.apid!,
        expeditor:creator,
        status:SessionStatus.Draft,
        signatures:[],
        documents:[],
        validity:new TSInterval(now, now.dateByAddingWeeks(2))
    }
}

const emptySession = async(): Promise<SessionDto> => {
    const uid = $UUID(null) ;
    const now = TSDate.zulu() ;
    return {
        title:"",
        creator:uid!,
        initialCreator:uid!,
        expeditor:{ apid: uid!, email:$email('empty@empty.com')!, firstName: 'Empty', lastName: 'Empty' },
        status:SessionStatus.Draft,
        signatures:[],
        documents:[],
        validity:new TSInterval(now, now.dateByAddingWeeks(2))
    }
}

const useSession = (sessionId: Nullable<UUID>, userID:Nullable<UUID>, token?: Nullable<string>) =>
  useQuery([SessionQueryID, sessionId], () => $ok(sessionId) ? getOneSessionForUser(sessionId!, token) : ($ok(userID) ? emptyCreatorSession(userID) : emptySession())) ;

export default useSession;
