import { Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, VStack } from "@chakra-ui/react";
import React from "react";

import { $count, $isstring, $length, $string } from "foundation-ts/commons";
import { Nullable } from "foundation-ts/types";
import { $map } from "foundation-ts/array";

import { ModalWindowState } from "./ModalConstants";

export const DefaultAsyncSelectModalState:AsyncSelectModalState = {
    items: null,
    onClose: async () => {},
    onSelect: async (index:number) => {},
} ;


export interface AsyncSelectItem {
    label: string ;
    complement?: string ;
    color: string ;
    bg: string ;
    hover: {
        color: string ;
        bg: string ; 
    } ;
    selected: {
        color: string ;
        bg: string ;
    }
}

export interface AsyncSelectModalState extends ModalWindowState {
    items: Nullable<Array<AsyncSelectItem|string>> ;
    onSelect: (index:number) => Promise<void>;
}

const DefaultItemColor = "white" ;
const DefaultItemBgColor = "blue.600" ;
export const AsyncSelectModal = (props: AsyncSelectModalState) => {
    const { onClose, items, title, message, onSelect, size = 'md' } = props ;

    return (
        <Modal onClose={onClose} size={size} isOpen={$count(items)>0} closeOnEsc={true} isCentered={true}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>{$string(title)}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>{$string(message)}</ModalBody>
                <VStack>                    
                    { $map(items, (item, index) => {
                        const iss = $isstring(item) ;
                        const label = iss ? item as string : (item as AsyncSelectItem).label ;
                        const color = iss || !$length((item as AsyncSelectItem).color) ? DefaultItemColor : (item as AsyncSelectItem).color! ;
                        const bgColor = iss || !$length((item as AsyncSelectItem).bg) ? DefaultItemBgColor : (item as AsyncSelectItem).bg! ;
                        const hoverColor = iss || !$length((item as AsyncSelectItem).hover.color) ? DefaultItemColor : (item as AsyncSelectItem).hover.color! ;
                        const hoverBgColor = iss || !$length((item as AsyncSelectItem).hover.bg) ? DefaultItemBgColor : (item as AsyncSelectItem).hover.bg! ;
                        const selectedColor = iss || !$length((item as AsyncSelectItem).selected.color) ? DefaultItemColor : (item as AsyncSelectItem).selected.color! ;
                        const selectedBgColor = iss || !$length((item as AsyncSelectItem).selected.bg) ? DefaultItemBgColor : (item as AsyncSelectItem).selected.bg! ;
                        const complement = iss || !$length((item as AsyncSelectItem).complement) ? '' : (item as AsyncSelectItem).complement! ;
                        return (
                            <Box 
                                as="button"
                                p="2"
                                width="100%"
                                minH="3em"
                                color={color}
                                bg={bgColor}
                                _hover={{ bg:hoverBgColor, color:hoverColor }}
                                _focus={{ bg:selectedBgColor, color:selectedColor }}
                                onClick={async () => onSelect(index)}
                            >
                                <VStack overflow="hidden" width="100%" spacing="3" align="stretch">
                                    <Box textAlign="left" as="h4" fontWeight="bold">
                                        <span color={color}>{label}</span>
                                    </Box>
                                    {complement?.length > 0 && (
                                        <Box textAlign="left" as="p" fontWeight="100" fontSize="small">
                                            <span>{complement}</span>
                                        </Box>
                                    )}
                                </VStack>
                            </Box>
                        )
                    })}
                </VStack>
                <ModalFooter margin="auto"></ModalFooter>
            </ModalContent>
        </Modal>
    ) ;
} ;