import React, { useState, useEffect } from "react";

import { $count, $isfunction, $length, $ok, $unsigned } from "foundation-ts/commons";
import { $round } from "foundation-ts/number"
import { Nullable, uint, uint8 } from "foundation-ts/types";
import { DocumentDto, SignatureDto } from "g1-commons/lib/doxecureClientTypes";

import { getDocumentSource } from "../../hooks/useDocumentSource";
import { okOrEmptyCard, optlog } from "../../utils/functions";

import { ViewerDocument } from "./ViewerDocument";
import "./Viewer.css";

interface ViewerAction {
    html:string|JSX.Element ;
    action:(index:Nullable<number>) => void|Promise<void> ;
}
interface ViewerProps {
    document: DocumentDto;
    lastPageSignRows: uint8;
    immutable: boolean;
    signatures?: SignatureDto[];
    forceMobile?: boolean;
    forceEmbed?: boolean;
    index?: number;
    count?: number ;
    originalScale?: number ;
    topAnchor?: string ;
    bottomAnchor?: string ;
    footerMessage?: string ;
    footerAnchor?: string ;
    specificViewerAnchor?: string ;
    token?: Nullable<string> ;
    viewerActions?: Nullable<Array<ViewerAction>>
    onLoadPDF?: (pdf:Uint8Array, pagesCount:number) => void
}

export const ViewerMinScale = 0.25 ;
export const ViewerMaxScale = 2 ;


export const Viewer = (props: ViewerProps) => {
  const { document, signatures, lastPageSignRows, immutable, index, count, originalScale, token, bottomAnchor, topAnchor, footerMessage, footerAnchor, specificViewerAnchor, viewerActions, onLoadPDF } = props ; 
  const [numPages, setNumPages ] = useState(0);
  const [scale, setScale] = useState<number>(_scaleRound($unsigned(originalScale,1 as uint))) ;
  const [file, setFile] = useState<Nullable<string>>(undefined) ;
  const [isLoading, setLoading] = useState(true) ;
  const isError = false ;
  const hasScrollIntoView = $isfunction(window.document.documentElement.scrollIntoView) ;
  const bcaViewerAnchor = $length(specificViewerAnchor) ? specificViewerAnchor! : 'bcaViewverAnchor' ;

  optlog(`will render document "${document.fileName}"`) ;

  useEffect(() => {
    console.log("useEffect Viewer") ;
    getDocumentSource(document, immutable, lastPageSignRows, signatures, token)
    .then(data => {
        setLoading(false) ;
        setFile(data?.pdfUrlData) ;
        optlog(`--- Did load document source "${document.fileName}"`) ;
    });
  }, [document, signatures, lastPageSignRows])

  const scrollToAnchor = (identifierName:string) => {
    if (hasScrollIntoView) {
        const anchorElement = window.document.getElementById(identifierName) ;
        if ($ok(anchorElement)) { anchorElement!.scrollIntoView() ; }
    }
  }

  const onDocumentLoadSuccess = (pdfData:Uint8Array, pagesCount:number) => {
        setNumPages(pagesCount);
        if ($isfunction(onLoadPDF)) { onLoadPDF!(pdfData, pagesCount) ;}
  };

  const renderNumPages = (n:number) => {
    if (n <= 0) { return null ; }
    return ( <i>&nbsp;&nbsp;{n === 1 ? "(une page)" : `(${n} pages)`}</i>) ;
  }

  const renderViewerActions = (actions:Nullable<ViewerAction[]>) => {
    const n = $count(actions) ;
    if (n === 0) { return null ; }
    return (
        <>
            {actions!.map((a, index) => (
                <a className="internalLink" key={`v_act${index}`} onClick={ () => a.action(index) }>{a.html}</a>
            ))}
            &nbsp;&nbsp;&nbsp;
        </>
    )
  }

  const onReduceScale = () => {
    const s = _scaleRound(scale-0.25) ;
    if (s !== scale) { setScale(s) ; }
  }

  const onAugmentScale = () => {
    const s = _scaleRound(scale+0.25) ;
    if (s !== scale) { setScale(s) ; }
  }
  const onStandardScale = () => {
    if (scale != 1) { setScale(1) ; }
  }
  return (
    <div className='bcaViewerBox' id={`${bcaViewerAnchor}${$ok(index) && $ok(count) ? index:'_unique'}`}>
      {$ok(index) && $ok(count) ?
        <div className="bcaViewerHeaderBox">
            <span className="flex_text">
                {count == 1 ? "Document: ":`Document ${index!+1}/${count!}: `}
                <b>{document.fileName}</b>
                {renderNumPages(numPages)}
            </span>
            <span className="flex_icons">
                {renderViewerActions(viewerActions)}
                <a className={scale<=ViewerMinScale?"disabledLink":"internalLink"} onClick={onReduceScale}>−</a>
                <a className={scale==1?"disabledLink":"internalLink"} onClick={onStandardScale}>➀</a>
                <a className={scale>=ViewerMaxScale?"disabledLink":"internalLink"} onClick={onAugmentScale}>+</a>
                &nbsp;&nbsp;&nbsp;
                {hasScrollIntoView && (index! > 0 || $length(topAnchor) > 0) ? 
                    <a className="internalLink" onClick={() => scrollToAnchor($length(topAnchor) > 0 ? topAnchor! :`${bcaViewerAnchor}${index!-1}`)}>⬆︎</a> :
                    null}
                
                {hasScrollIntoView && ($length(bottomAnchor) > 0 || (index!+1 < count!)) ? 
                    <a className="internalLink" onClick={() => scrollToAnchor($length(bottomAnchor) > 0 ? bottomAnchor! : `${bcaViewerAnchor}${index!+1}`)}>⬇︎</a> :
                    null}
            </span>

        </div> 
      : ($ok(originalScale) ? 
          <div className="bcaViewerHeaderBox">
            <span className="flex_text">
                Document: <b>{document.fileName}</b>
                {renderNumPages(numPages)}
            </span>
            <span className="flex_icons">
                {renderViewerActions(viewerActions)}
                <a className={scale<=ViewerMinScale?"disabledLink":"internalLink"} onClick={onReduceScale}>−</a>
                <a className={scale==1?"disabledLink":"internalLink"} onClick={onStandardScale}>➀</a>
                <a className={scale>=ViewerMaxScale?"disabledLink":"internalLink"} onClick={onAugmentScale}>+</a>
            </span>
          </div>
      : null)}
      {okOrEmptyCard(
        file, 
        (
            <ViewerDocument file={file} onLoad={onDocumentLoadSuccess} scale={scale}/>
        ), isLoading, isError)}
      {$ok(index) && $ok(count) && index!+1 === count! && $length(footerMessage) > 0 ?
        <div className="bcaViewerFooterBox">
            <span className="flex_text">
                {footerMessage}
            </span>
            <span className="flex_icons">
                {hasScrollIntoView ? 
                    <a className="internalLink" onClick={() => scrollToAnchor($length(footerAnchor)?footerAnchor!:`${bcaViewerAnchor}${index!}`)}>⬆︎</a>
                :null}
            </span>
        </div>
      : null }
    </div>
  );
};

function _scaleRound(s:number):number {
    s = $round(s*4) / 4.0 ;
    return Math.max(ViewerMinScale, Math.min(ViewerMaxScale, s)) ;
}