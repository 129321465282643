import React, { useEffect, useState } from "react";
import * as pdfjs from 'pdfjs-dist';
import { PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist";

import { $count, $isfunction, $length, $ok } from "foundation-ts/commons";
import { $decodeBase64 } from "foundation-ts/data";
import { Nullable } from "foundation-ts/types";

import { optlog } from "../../utils/functions";

import { ViewerPageContainer } from "./ViewerPageContainer";
import { ViewerPage } from "./ViewerPage";
import './ViewerDocument.css';

interface ViewerDocumentProps {
    file: Nullable<string> ; // string is an URLData
    workerSrc?: string ;
    onLoad?:(pdf:Uint8Array, pagesCount:number) => void ;
    scale?: number
}

export const ViewerDocument = (props:ViewerDocumentProps) => {
    const { 
        file, 
        workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`,
        onLoad,
        scale
    } = props;

    const [pages, setPages] = useState<PDFPageProxy[]>([]);
    const [pdfDocument, setPdfDocument] = useState<PDFDocumentProxy|undefined>(undefined);

    const prefix = 'data:application/pdf;base64,';
    
    useEffect(() => { 
        if ($length(file)>0) {
            (async () => {
                pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;
                const content = $decodeBase64(file!.slice(prefix.length)) ; 
                const pdf = await pdfjs.getDocument({data:content}).promise ;
                const numPages = pdf.numPages ;
                const loadedPages:PDFPageProxy[] = [] ;
                for (let i = 1 ; i <= numPages ; i++) {
                    const page = await pdf.getPage(i) ;
                    loadedPages.push(page)
                    //optlog(`ViewerDocument did load page ${i}`)
                }
                optlog(`ViewerDocument did load a pdf with ${numPages} pages`)
                setPages(loadedPages) ;
                setPdfDocument(pdf) ;
                if ($isfunction(onLoad)) { onLoad!(content, numPages) ; }
            })() ;

        }
    }, [file]) ; 

 
    return ($ok(pdfDocument) && $count(pages) > 0 ? (
        <div className="bcaViewerDocumentBox">
            {pages.map((page, index) => (
                <ViewerPageContainer key={`vp${index}`}>
                    <ViewerPage page={page} scale={scale} />
                </ViewerPageContainer>
            ))}
        </div>
    ) : (<></>) ) ;

}
// import React, { useEffect, useState } from "react";
// import * as pdfjs from 'pdfjs-dist';
// import { PDFDocumentProxy, PDFPageProxy } from "pdfjs-dist";

// import { $count, $isfunction, $length, $ok } from "foundation-ts/commons";
// import { $decodeBase64 } from "foundation-ts/data";
// import { Nullable } from "foundation-ts/types";

// import { ViewerPageContainer } from "./ViewerPageContainer";
// import { ViewerPage } from "./ViewerPage";

// import { optlog } from "../../utils/functions";

// interface ViewerDocumentProps {
//     file: Nullable<string> ; // string is an URLData
//     workerSrc?: string ;
//     onLoad?:(pdf:Uint8Array, pagesCount:number) => void ;
// }

// export const ViewerDocument = (props:ViewerDocumentProps) => {
//     const { 
//         file, 
//         workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`,
//         onLoad
//     } = props;

//     const [pages, setPages] = useState<PDFPageProxy[]>([]);
//     const [pdfDocument, setPdfDocument] = useState<PDFDocumentProxy|undefined>(undefined);
//     const prefix = 'data:application/pdf;base64,';
//     const stylemin = {
//         border: "1px solid #ccc",
//         background: "#ddd"
//     }
//     const style = {
//         ...stylemin,
//         width:"100%",
//         height:"100%"
//         // "overflow-y":"auto"
//       };
    
//     useEffect(() => { 
//         if ($length(file)>0) {
//             (async () => {
//                 pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;
//                 const content = $decodeBase64(file!.slice(prefix.length)) ; 
//                 const pdf = await pdfjs.getDocument({data:content}).promise ;
//                 const numPages = pdf.numPages ;
//                 const loadedPages:PDFPageProxy[] = [] ;
//                 for (let i = 1 ; i <= numPages ; i++) {
//                     const page = await pdf.getPage(i) ;
//                     loadedPages.push(page)
//                     //optlog(`ViewerDocument did load page ${i}`)
//                 }
//                 optlog(`ViewerDocument did load a pdf with ${numPages} pages`)
//                 setPages(loadedPages) ;
//                 setPdfDocument(pdf) ;
//                 if ($isfunction(onLoad)) { onLoad!(content, numPages) ; }
//             })() ;

//         }
//     }, [file]) ; 

//     // useEffect(() => {
//     //     pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;
//     // }, [workerSrc]);
 
//     return ($ok(pdfDocument) && $count(pages) > 0 ? (
//         <div style={style}>
//             {pages.map((page, index) => (
//                 <ViewerPageContainer key={`vp${index}`} style={stylemin}>
//                     <ViewerPage page={page} />
//                 </ViewerPageContainer>
//             ))}
//         </div>
//     ) : (<></>) ) ;

// }