import { useToast, useDisclosure, Checkbox } from "@chakra-ui/react";
import { Box, Center, Flex, HStack, Stack, Text, VStack } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { navigate } from "gatsby";

import { $map } from "foundation-ts/array";
import { $count, $isfunction, $isuuid, $length, $ok, $string, $url, $value } from "foundation-ts/commons";
import { $uuid } from "foundation-ts/crypto";
import { $decodeBase64 } from "foundation-ts/data";
import { TSColor } from "foundation-ts/tscolor";
import { TSDate } from "foundation-ts/tsdate";
import { $bool } from "foundation-ts/tsparser";
import { Nullable, TSDataLike, TSDictionary, uint, UUID } from "foundation-ts/types";
import { $inspect } from "foundation-ts/utils";
import { DocumentDto, SignatureDto, UploadFileBody } from "g1-commons/lib/doxecureClientTypes";
import { SessionStatus, SignaturePhase, SignatureStatus } from "g1-commons/lib/doxecureModelTypes";
import { SignatureMode, UploadedDocumentFileStructure } from "g1-commons/lib/doxecureTypes";
import { BCAQSCDBackend, BCAQSCDCertificateInfo, BCAQSCDReturnCode, BCASignatureOptions, BCASigningBackend, BCASigningLevel, BCAVisibleSignatureOptions, createQSCDSigner, setIdopteChallengeURL, setIdopteWebappCert } from "bca-client-signature/lib";
import { BCASignaturePosition } from "bca-client-signature/lib/BCAPDFSignatureZones";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { ColumnTitle } from "../../components/ColumnTitle/ColumnTitle";
import { G1Button } from "../../components/G1Button";
import { Link } from "../../components/Link/Link";
import { SeaShellBox } from "../../components/SeaShellBox";
import { Viewer } from "../../components/Viewer/Viewer";
import { AsyncConfirmModal, DefaultAsyncConfirmModalState } from "../../components/Modals/AsyncConfirmModal";
import { AsyncRefuseModal } from "../../components/Modals/AsyncRefuseModal";
import { AsyncSelectModal } from "../../components/Modals/AsyncSelectModal";
import { WaitingModal } from "../../components/Modals/WaitingModal";

import { config } from "../../config/config";
import { useDevice } from "../../hooks/useDevice";
import useSession from "../../hooks/useSession";
import { DocumentContentID, SectionsListQueryID } from "../../hooks/hooksConstants";
import { getDocumentRepresentation } from "../../services/session.service";
import apiClient, { g1call } from "../../services/apiClient";
import { G1Icon } from "../../utils/icons";
import { bytesToDownloadURL, canSessionBeSigned, optlog, trimOnWords, trueOrEmptyCard } from "../../utils/functions";
import { defaultErrorToast, defaultSuccessToast } from "../../utils/toast";

interface DocumentsViewerProps {
    validityDate: string ;
    senderName: string ;
    immutableDocuments: boolean ;
    acceptsSignature: boolean ;
    profileId?: Nullable<UUID> ;
    sessionId?: UUID ;
    token?: Nullable<string> ;
    signatureMode?: SignatureMode ;
    resetAndBack?: () => void ;
    deleteSession?: () => void ;
    editSession?: () => void ;
    onExternalSignature?: () => void ;
}

export const DocumentsViewer = (props:DocumentsViewerProps) => {
    const { validityDate, sessionId, senderName, profileId, immutableDocuments, acceptsSignature, token, signatureMode, editSession, deleteSession, onExternalSignature, resetAndBack } = props ;
    const { data:session, isError, isLoading } = useSession(sessionId, profileId, token) ;
    const [visibleDocuments, setVisibleDocuments] = useState<DocumentDto[]>([]) ;
    const [signers, setSigners] = useState<SignatureDto[]>([]) ;
    const [refusedSigners, setRefusedSigners] = useState<SignatureDto[]>([]) ;
    const [futureSigners, setFutureSigners] = useState<SignatureDto[]>([]) ;
    const [ownerSignature, setOwnerSignature] = useState<SignatureDto|undefined>(undefined) ;
    const [signatureLink, setSignatureLink] = useState('') ;
    const [backend, setBackend] = useState<Nullable<BCAQSCDBackend>>(undefined) ;
    const [certificates, setCertificates] = useState<BCAQSCDCertificateInfo[]>([]) ;
    const [confirmModal, setConfirmModal] = useState(DefaultAsyncConfirmModalState) ;
    const [isOpenRefuseModal, setIsOpenRefuseModal] = useState(false) ;
    const [waitMessage, setWaitMessage] = useState('') ;
    const [creatorOrExpeditor, setCreatorOrExpeditor] = useState(false) ;
    const [checkedConfirm, setCheckedConfirm] = useState(false) ;
    const toast = useToast();
    const queryClient = useQueryClient();
    const { isOpen, onOpen, onClose } = useDisclosure() ;
    const { isMOBILE } = useDevice() ;
    const now = TSDate.zulu() ;
    const bottomAnchor = "DocumentsViewerBottomAnchor" ;
    const topAnchor = "DocumentsViewerTopAnchor"

    setIdopteWebappCert(process.env.SCWS_WEB_APPCERT) ;
    if ($length(process.env.SCWS_CHALLENGE_URL)) { 
        setIdopteChallengeURL(process.env.SCWS_CHALLENGE_URL) ; 
    }

    const handleSignature = async (b:BCAQSCDBackend, certificate:BCAQSCDCertificateInfo) => {
        const certificateID = certificate.identifier ;
        setConfirmModal({
            title:"Signature de documents",
            message: [
                "En cliquant sur signer vous allez procéder à la signature de l'ensemble des documents",
                `avec le certificat de signature ${certificate.commonName} délivré par ${certificate.issuerName}.`,
                "En effectuant cette signature vous actez le fait d'avoir pris connaissance de ces documents."
            ].join(' '),
            cancel:"Annuler",
            confirm:"Signer",
            onClose: async () => {
                setConfirmModal(DefaultAsyncConfirmModalState) ;
                _cancelSignatureProcess() ;
            },
            onAccept: async () => {
                setConfirmModal(DefaultAsyncConfirmModalState) ;
                await _handleSignatureProcess(b, certificateID) ;
            },
        });
    };
    
    const _fileDownloadURL = (url:string, fileName:string) => {
        optlog('>>> >>> >>> >>> +++') ;
        optlog('want to download file', fileName) ;
        const link = document.createElement('a') ;
        link.href = url;
        link.download = fileName ;
        link.click();        
    }

    const _linkAction = (actionUrl:string, download?:boolean) => {
        if ($length(actionUrl) > 0) {
            const link = document.createElement('a') ;
            optlog('>>> >>> >>> >>> +++') ;
            if (!download) {
                optlog('want to open new link', actionUrl) ;
                link.target = '_blank';
                link.rel = 'noopener noreferrer';    
            }
            else {
                optlog('want to download link', actionUrl) ;
            }
            link.href = actionUrl;
            link.click();        
        }
    }

    const handleAction = async (action:Action):Promise<void> => {
        optlog(`Did make action ${action}`) ;
        switch (action) {
            case Action.modify:
                if ($isfunction(editSession)) { editSession!() ; }
                break ;
            case Action.delete:
                if ($isfunction(editSession)) { deleteSession!() ; }
                break ;
            case Action.download:
                if (session?.status !== SessionStatus.Signed) { return ; }
                optlog('Clicked on Download') ;
                const ret = await g1call('download zip of signed documents', c => c.downloadSignedDocuments(session!.apid!)) ;
                if (!$ok(ret)) {
                    toast(defaultErrorToast('Impossible de télécharger les documents')) ;
                    return ;
                }
                const filename = `${session!.title}.zip` ;
                const [zip,] = ret! ;
                if (isMOBILE) {
                    const manager = await createQSCDSigner({
                        applicationName:config.app.title,
                        timeouts: config.timeouts
                    }) ; 
                    manager?.downloadSignedDocuments(filename, zip!.toBase64()) ;                  
                } else {
                    const url = bytesToDownloadURL(zip) ;
                    if ($length(url) === 0) {toast(defaultErrorToast('Impossible de télécharger les documents')) ; }
                    else { _fileDownloadURL(url!, filename) ; }
                }
                break ;
            case Action.sign:
                if (ownerSignature?.phase === SignaturePhase.Server) {
                    _linkAction(signatureLink) ;
                    if ($isfunction(onExternalSignature)) { onExternalSignature!() ;}
                }
                else if ($ok(ownerSignature)) {
                    optlog('QSCD >>> >>> >>> >>> +++') ;
                    setWaitMessage('Récupération des certificats en cours') ;
                    onOpen();
                    const manager = await createQSCDSigner({
                        applicationName:config.app.title,
                        timeouts: config.timeouts,
                        debug: true
                    }) ;                   
                    setBackend(manager) ;
                    if ($ok(manager)) {
                        optlog('Connected to Idopte system') ;
                        const managerCertificates = await manager?.getCertificates() ;
                        onClose(); 
                        const n = $count(managerCertificates) ;
                        if (n > 0) {
                            if (n === 1) {
                                await handleSignature(manager!, managerCertificates!.first()!) ;
                            }
                            else {
                                // we have to select the certificates inside a modal window
                                // this will open the modal window
                                setCertificates(managerCertificates!) ;
                            }
                        }
                        else { 
                            toast(defaultErrorToast("Votre dispositif de signature ne comporte pas de certificats.")) ;
                        }
                    }
                    else { 
                        onClose();
                        toast(defaultErrorToast("Impossible de se connecter au système de signature.")) ;
                    }
                }
                break ;
            case Action.refuse:
                setIsOpenRefuseModal(true) ;
                break ;
            case Action.cancel:
                setConfirmModal({
                    title:"Annulation de la session",
                    message:"Êtes-vous bien sur de vouloir annuler cette session ?",
                    confirm:"Oui, j'annule",
                    onClose: async () => { setConfirmModal(DefaultAsyncConfirmModalState) ; },
                    onAccept: async () => {
                        _handleCancelSession() ;
                        setConfirmModal(DefaultAsyncConfirmModalState) ;
                    }
                }) ;
                break ;
            case Action.createTemplate:
                navigate(`/app/newTemplate/${session?.apid}`) ;
                break ;
            default:
                optlog("Unknown action") ;
                break ;
        }
    } ;

    const _cancelSignatureProcess = (success?:boolean) => {
        setCertificates([]) ;
        setBackend(undefined) ;
        toast(defaultErrorToast("Opération de signature annulée.")) ; 
    } ;

    const _handleRefuseSignature = async(reason: string) => {
        setWaitMessage('Refus de signature en cours') ;
        onOpen() ;
        const refusalDate = TSDate.zulu() ;
        
        optlog('############################################') ; 
        optlog('sessionID', session?.apid) ;
        optlog('signatureID', ownerSignature?.apid) ;
        optlog('signerID', ownerSignature?.signer?.apid) ;
        optlog('profileID', profileId) ;
        optlog('ownerSignature', ownerSignature) ;
        optlog('refusalDate', refusalDate) ;
        optlog('refusalReason', reason) ;
        optlog('############################################') ; 
        
        const done = await g1call('refuse signature', (c) => c.refuseSignature(session!.apid!, ownerSignature!.apid!, ownerSignature!.signer!.apid!, refusalDate, reason, token)) ;
        if (!!done) {
            queryClient.invalidateQueries([DocumentContentID, SectionsListQueryID]) ;
            toast(defaultSuccessToast("Refus de signature pris en compte.")) ;
            if ($ok(token)) {
                await g1call('expire token access', (c) => c.deleteSession(session!.apid!, token)) ;
                navigate('/result-external-sign?ret=0') ;
            } else {
                navigate(`/app/refusedDocuments`, { replace: true });
            }
        }
        else {
            toast(defaultErrorToast("Le refus de signature n'a pas pu aboutir.")) ; 
        }
        onClose() ;
        setWaitMessage('') ;
    } ;

    const _handleCancelSession = async() => {
        setWaitMessage('Annulation de la session en cours') ;
        onOpen() ;
        const cancelDate = TSDate.zulu() ;

        optlog('############################################') ; 
        optlog('sessionID', session?.apid) ;
        optlog('profileID', profileId) ;
        optlog('refusalDate', cancelDate) ;
        optlog('############################################') ; 
        
        const done = await g1call('cancel signature', (c) => c.abortSession(session!.apid!)) ;

        if (!!done) {
            queryClient.invalidateQueries([DocumentContentID, SectionsListQueryID]) ;
            toast(defaultSuccessToast("Annulation de la session prise en compte.")) ;
            navigate(`/app/canceledDocuments`, { replace: true });
        }
        else {
            toast(defaultErrorToast("L'annulation de la session n'a pas pu aboutir.")) ; 
        }
        onClose() ;
        setWaitMessage('') ;
    }

    const _handleSignatureProcess = async (b:BCAQSCDBackend, certificateID:UUID) => {
        setWaitMessage('Signature avec votre dispositif ...') ;
        onOpen() ;
        const done = await _proceedToSignature(b, certificateID) ;
        if (done) {
            const flen = futureSigners.length ;
            queryClient.invalidateQueries([DocumentContentID, SectionsListQueryID]) ;
            toast(defaultSuccessToast("Opération de signature effectuée."))
            setBackend(undefined) ;
            setCertificates([]) ;
            if ($ok(token)) {
                await g1call('expire token access', (c) => c.deleteSession(session!.apid!, token)) ;
                navigate('/result-external-sign?ret=1') ;
            } else {
                navigate((flen === 1) ? `/app/signedDocuments` : `/app/tobeSignedDocuments`, { replace: true });
            }
        }
        else { _cancelSignatureProcess() ; }
        onClose() ;
    }

    const _proceedToSignature = async (b:BCAQSCDBackend, certificateID:UUID):Promise<boolean> => {
        const errors:string[] = [] ;
        function error(e:string):boolean { errors?.push(e) ; toast(defaultErrorToast(e)) ; return false ; }

        if (!$ok(b)) { return error("Le système de signature n'est plus accessible.") ; }
        if (!$isuuid(certificateID)) { return error('Mauvais identificateur de certificat de  signature.') ; }
        if (!$isuuid(session?.apid)) { return error('Erreur interne: impossible de trouver la session de signature') ; }
        if (!$isuuid(ownerSignature?.apid)) { return error('Erreur interne: impossible de trouver le signataire') ; }

        const documentsCount = $count(visibleDocuments) ;
        if (!documentsCount) { return error("Erreur interne: impossible de lister les documents à signer.") ; }
        if (visibleDocuments!.filter(d => !$ok(d.apid))?.length > 0) { return error("Erreur interne: certains documents ne sont pas complets.") ; }

        const signatureOptions: BCASignatureOptions = { signingLevel: BCASigningLevel.B, hashMethod: config.backend.hashMethod, checkRevocation: config.backend.checkRevocation } ;
        const serverSignature = session!.signatures!.filter(sign => sign.phase === SignaturePhase.Server).first() ;
        if ($ok(serverSignature) && (session!.signatures!.indexOf(ownerSignature!) < session!.signatures!.indexOf(serverSignature!))) {
            signatureOptions.signingLevel = BCASigningLevel.LT ;
        }
        if (signatureOptions.signingLevel === BCASigningLevel.LT) {
            signatureOptions.timestamp = async (requestBody: TSDataLike, _timeout?: Nullable<uint>): Promise<Nullable<TSDataLike>> => { 
                const tsBase64Encoded = await apiClient.requestTimestamp(requestBody, token) ;
                return $ok(tsBase64Encoded) ? $decodeBase64(tsBase64Encoded!) : null ;
            } ;
            signatureOptions.downloadCRL = async (url: string, _timeout?: Nullable<uint>): Promise<Nullable<TSDataLike>> => { 
                const crlBase64Encoded = await apiClient.downloadCRL(url, token) ;
                return $ok(crlBase64Encoded) ? $decodeBase64(crlBase64Encoded!) : null ;
            } ;
            signatureOptions.requestOCSP = async (url: string, request: TSDataLike, _timeout?: Nullable<uint>): Promise<Nullable<TSDataLike>> => { 
                const ocspBase64Encoded = await apiClient.requestOCSP(url, request, token) ;
                return $ok(ocspBase64Encoded) ? $decodeBase64(ocspBase64Encoded!) : null ;
            } ;
        }
        const localSession = await b.openSignatureSession(certificateID, errors, signatureOptions) ;
        if (typeof localSession === 'number') { return error(_signErrorMessage(localSession)) ; }
        else if ($ok(localSession)) {
            let localError = '' ;
            let signedDocuments:UploadedDocumentFileStructure[] = [] ;
            try {
                for (let i = 0 ; i < documentsCount && localError.length === 0 ; i++) {
                    const d = visibleDocuments![i] ;
                    const representation = await getDocumentRepresentation(session!.apid!, d.apid!, token) ;
                    optlog('------------------------------------------------------') ;
                    optlog('ownerSignature', ownerSignature)
                    optlog('visible signatures', representation.visibleSignatures) ;
                    optlog('------------------------------------------------------') ;
                    let signret: string|BCAQSCDReturnCode = '' ;
                    if (session?.signatures?.first()?.signatureMode === SignatureMode.Visible) {
                        const position = representation.visibleSignatures?.find(r => r.identifier === ownerSignature!.apid) ;
                        if (!$ok(position)) { localError = `Erreur interne: impossible de trouver la position de la signature sur un des documents.` ; }
                        else {
                            const signPosition:BCASignaturePosition = { ... position!, page:position!.page + 1 } ;
                            const visibleSignatureOptions: BCAVisibleSignatureOptions = {
                                position: signPosition,
                                background: {
                                    color: TSColor.white()
                                },
                                namePrefix: "Signé par"
                            } ;
                            signret = await localSession.signPDF(representation.pdfUrlData, visibleSignatureOptions, errors) ;
                        }
                    } else {
                        signret = await localSession.invisibleSignPDF(representation.pdfUrlData, null, errors) ;
                    }
                    if (typeof signret === 'number') { localError = _signErrorMessage(signret) ; }
                    else if (!$length(signret)) { localError = "Le PDF signé n'a pas été correctement généré." ; }
                    else {
                        // here we have the base64 representation of the PDF
                        const body:UploadFileBody = { source:signret!, fileName:`${$uuid()}.pdf`} ;
                        const uploadedFile = await g1call('upload file', (c) => c.uploadFile(body, sessionId, token)) ;                            
                        if (!$ok(uploadedFile)) { 
                            localError = "Impossible de transférer le fichier PDF signé." ;
                        } else { 
                            signedDocuments.push({...uploadedFile!, apid:d.apid!})
                            optlog('+++++++++++++++++++++++++++++++++++') ;
                            optlog('did upload signed document', uploadedFile)
                            optlog('+++++++++++++++++++++++++++++++++++') ;
                        }
                    }
                    signret = '' ;
                }
            }
            catch (e) {
                localError = `erreur durant le process de signature (${e.message})` ;
            }
            localSession.closeSession() ;
            if (localError.length) { return error(localError) ; }
            optlog('------------------------------------------------------') ;
            optlog('Did properly close QSCD signature session')
            optlog('session.apid', session!.apid!)
            optlog('profileId', profileId!)
            optlog('------------------------------------------------------') ;
            const signatureIsComplete = await g1call('update signed documents', c => c.updateSignedDocuments(session!.apid!, ownerSignature!.apid!, profileId!, TSDate.zulu(), signedDocuments, null, token)) ;

            if (!signatureIsComplete) {
                // FIXME: if !signatureComplete, we need to remove temporary uploadeded documents
                return error("Une erreur est arrivée lors de la sauvegarde de vos documents signés. L'opération est annulée.")
                //const remainingFiles = await g1call('remove uploaded files', c => c.removeUploadedFiles(signedDocuments)) ;
                //if ($count(remainingFiles)>0) { toast(defaultWarningToast("Certains fichiers temporaires créés lors de l'opération n'ont pas été supprimés.")) ; }
            }

            return !!signatureIsComplete ;
        }
        else { return error("Impossible d'ouvrir une session de signature sur votre dispositif.") ; }
    } ;

    useEffect(() => {
        optlog(`--- DocumentsViewer initialized with sessionID:'`, $inspect(session?.apid)) ;
        const docs = $value(session?.documents, []) ;
        setVisibleDocuments(docs) ;
        setSigners($map(session?.signatures, s => $ok(s.signatureDate) && s.signatureStatus === SignatureStatus.Signed ? s : undefined)) ;
        setFutureSigners($map(session?.signatures, s => !$ok(s.signatureDate) || (s.signatureStatus === SignatureStatus.Canceled) ? s : undefined)) ;
        setRefusedSigners($map(session?.signatures, s => (s.signatureStatus === SignatureStatus.Refused) ? s : undefined)) ;
        const owner = $ok(session) && !!canSessionBeSigned(session!, profileId) && acceptsSignature ? session!.signatures?.find(s => s.signer.apid === profileId) : undefined ;
        setOwnerSignature(owner) ;
        const link = $value($string($url(owner?.link)), '') ;
        setSignatureLink(link) ;
        setCreatorOrExpeditor(session?.expeditor === profileId || session?.creator === profileId) ;
    }, [session]);

    return trueOrEmptyCard(
        $ok(profileId) && $ok(sessionId) && $ok(session),
        !visibleDocuments.length ? (
            <VStack h="full">
                <SeaShellBox component="form" height="auto">
                    <HStack mt="unset" height="auto" display="flex" justifyContent="center" flexDirection={{ base: "column", sm: "row" }}>
                        { renderButtons(session?.status, creatorOrExpeditor, $bool(session?.validity.containsDate(now)), $ok(session?.templateId), undefined, handleAction) }
                    </HStack>
                </SeaShellBox>
                <Text display="flex" flexDirection="column" justifyContent="center" height="100%" textAlign="center" color="lightgrey">
                    Pas de documents dans cette session de signature
                </Text>
            </VStack>
        ) : (
            <>
                {isMOBILE && $isfunction(resetAndBack) &&
                    <ColumnTitle color={$g1Color('global.write')}>
                        <Flex alignItems="center" as="button" onClick={() => resetAndBack!()}>
                            <Center color={$g1Color('global.icons.navigation.previous.write')}><G1Icon.Back /></Center>
                            <Text>Retour</Text>
                        </Flex>
                    </ColumnTitle>
                }

                {/* encart supérieur seulement pour les abonnés */}
                {$ok(token) ?
                    <div id={topAnchor} />
                    : <SeaShellBox component="form" id={topAnchor} height="auto" mt="10" mb="10">
                        <HStack mt="unset" height="auto" display="flex" flexDirection={{ base: "column", sm: "row" }}>
                            <VStack align="stretch" width="100%">
                                <Stack align="stretch">
                                    <Text decoration="underline">Liste des documents à signer</Text>
                                    <Text fontSize="medium">{`Mis à la signature par ${senderName} ${signatureMode === SignatureMode.Invisible ? "(Signatures non visibles dans le document)" : ""}`}</Text>
                                    {!$ok(ownerSignature) && (signers.length !== 0) && renderSigners(signers, "Déja signé par :")}
                                    {!$ok(ownerSignature) && (refusedSigners.length !== 0) && renderSigners(refusedSigners, "Refusé par :")}
                                    {!$ok(ownerSignature) && (futureSigners.length !== 0) && renderFutureSigners(futureSigners, (refusedSigners.length !== 0) ? "Devait encore être signé par :" : "Doit être signé par :")}
                                    {$ok(ownerSignature) && <Text fontSize="medium">Vous devez consulter l'ensemble des documents présentés ci-dessous pour pouvoir les signer.</Text>}
                                </Stack>
                            </VStack>

                            <Stack margin="5%" display="flex" justifyContent="center" width={{ base: "100%", sm: "unset" }} height="100%" flexDirection={{ base: "row", sm: "column" }} spacing={{ base: "unset", sm: "2" }} alignItems="center" paddingTop={{ base: "2%", sm: "unset" }}>
                                { renderButtons(session?.status, creatorOrExpeditor, $bool(session?.validity.containsDate(now)), $ok(session?.templateId), ownerSignature, handleAction) }
                            </Stack>
                        </HStack>
                    </SeaShellBox>
                }
                    
                {/* encart inférieur */}
                {visibleDocuments?.map((d, index) => (
                    <Viewer document={d} 
                        key={`vw${index}`} 
                        index={index} 
                        token={token}
                        forceMobile={true} 
                        immutable={immutableDocuments} 
                        lastPageSignRows={d.lastPageSignRows} 
                        count={visibleDocuments.length} 
                        signatures={$count(signers) === 0 ? [] : futureSigners} 
                        topAnchor={index === 1 ? topAnchor : undefined}
                        bottomAnchor={index+1 == visibleDocuments.length ? bottomAnchor : undefined } 
                        footerAnchor={index === 0 && visibleDocuments.length === 1 ? topAnchor : undefined } 
                    />
                ))}
                {session?.status === SessionStatus.ToBeSigned && $bool(session?.validity.containsDate(now)) && $ok(ownerSignature) && renderSignBox(ownerSignature, checkedConfirm, () => { setCheckedConfirm(!checkedConfirm) }, handleAction)}
                <WaitingModal isOpen={isOpen} onClose={onClose} message={waitMessage}/>
                <AsyncSelectModal 
                    title="Certificats de signature" 
                    message="Sélectionnez votre certificat de signature"
                    items={$map(certificates, c => { 
                        return {
                            label: trimOnWords(c.commonName, 25).capitalize(),
                            complement: `(${trimOnWords(c.issuerName,30)})`,
                            bg: $g1Color('list.certificates.bg'),
                            color: $g1Color('list.certificates.write'),
                            hover: { bg: $g1Color('list.certificates.hover.bg'), color: $g1Color('list.certificates.hover.write') },
                            selected: { bg: $g1Color('list.certificates.select.bg'), color: $g1Color('list.certificates.select.write') }
                        } ;
                    })} 
                    onClose={async() => _cancelSignatureProcess() }
                    onSelect={async(index:number) => {
                        setCertificates([]) ;
                        await handleSignature(backend!, certificates[index]) ;
                    }}
                />
                <AsyncRefuseModal isOpen={isOpenRefuseModal} onClose={async () => { setIsOpenRefuseModal(false) ; }} onAccept={(reason: string) => {
                    setIsOpenRefuseModal(false) ;
                    setConfirmModal({
                        title:"Refus de signature",
                        message:"Êtes-vous bien sûr d'avoir pris connaissance des documents à signer et de refuser de les signer ?",
                        confirm:"Oui, je refuse",
                        onClose: async () => { setConfirmModal(DefaultAsyncConfirmModalState) ; },
                        onAccept: async () => {
                            _handleRefuseSignature(reason) ;
                            setConfirmModal(DefaultAsyncConfirmModalState) ;
                        }
                    }) ;
                }} />
                <AsyncConfirmModal message={confirmModal.message} onClose={confirmModal.onClose} onAccept={confirmModal.onAccept} />
            </>
        ),
        isLoading,
        isError || !$ok(profileId) || !$ok(sessionId) || !$ok(session),
        "Impossible de récupérer les documents de cette session de signature",
        "Connexion invalide",
        "Chargement des documents de la session"
    ) ;

}


function _signErrorMessage(code:BCAQSCDReturnCode):string {
    switch(code) {
        case BCAQSCDReturnCode.PinTimeout: return "Le code PIN n'a pas été saisi" ;
        case BCAQSCDReturnCode.PinCanceled: return "La saisie du code PIN a été annulée" ;
        case BCAQSCDReturnCode.PinExpired: return "Code PIN expiré" ;
        case BCAQSCDReturnCode.PinLocked: return "Carte bloquée" ;
        case BCAQSCDReturnCode.PinFailed: return "Erreur inerne : saisie du code PIN impossible" ;
        case BCAQSCDReturnCode.InvalidSession: return "La session de signature a expiré" ;
        case BCAQSCDReturnCode.BadCertificateId: return "Erreur interne : format d'identifiant du certificat incorrecte" ;
        case BCAQSCDReturnCode.NoTokenFound: return "Erreur interne : impossible d'accéder au token" ;
        case BCAQSCDReturnCode.NoCertificateFound: return "Erreur interne : impossible de trouver le certificat de signature" ;
        case BCAQSCDReturnCode.NoPrivateKeyFound : return "Erreur interne : impossible d'accéder à la clé privée du certificat" ;
        case BCAQSCDReturnCode.SignFailed : return "Erreur interne: levée d'exception durant la signature" ;
        case BCAQSCDReturnCode.DisconnectFailed: return "Erreur interne: impossible de se déconnecter du dispositif de signature" ;
        default: return "Erreur interne inconnue" ;
    }
}
enum Action {
    modify,
    delete,
    download,
    sign,
    refuse,
    cancel,
    createTemplate
} ;

function renderButtons(status:Nullable<SessionStatus>, creatorOrExpeditor: boolean, validSession: boolean, fromTemplateId: boolean, signature:Nullable<SignatureDto>, callback:(action:Action, info?:TSDictionary)=>Promise<void>) {
    const buttonWidth = config.app.templateManagement && creatorOrExpeditor && !fromTemplateId ? "150px" : "100px" ;
    const createTemplateButton = config.app.templateManagement && creatorOrExpeditor && !fromTemplateId ? <G1Button component="form" variant="alternate" title="Créer un modèle" width={buttonWidth} fontSize="md" onClick={async () => callback(Action.createTemplate)} /> : null ;
    if (validSession) {
        const qscdPhase = signature?.phase !== SignaturePhase.Server ;
        const canSign = $ok(signature) && (!qscdPhase || config.app.qscd) ;
    
        switch (status) {
            case SessionStatus.Draft:
                return (
                    <>
                        <G1Button component="form" variant="normal" title="Modifier" width={buttonWidth} fontSize="md" onClick={async () => callback(Action.modify)} />
                        <G1Button component="form" variant="delete" title="Supprimer" width={buttonWidth} fontSize="md" onClick={async () => callback(Action.delete)} />
                        { createTemplateButton }
                    </>
                ) ;
            case SessionStatus.Signed:
                return (
                    <>
                        <G1Button component="form" variant="important" title="Télécharger" width={buttonWidth} fontSize="md" onClick={() => callback(Action.download)} />
                        { createTemplateButton }
                    </>
                ) ;
            case SessionStatus.ToBeSigned:
                return creatorOrExpeditor && !canSign && (
                    <>
                        <G1Button component="form" variant="delete" title="Annuler"width={buttonWidth} fontSize="md" onClick={() => callback(Action.cancel)} />
                        {createTemplateButton}
                    </>
                ) ;
            case SessionStatus.Refused:
            case SessionStatus.Canceled:
                return (<>{createTemplateButton}</>) ;
            default:
                return null ;
        }
    } else {
        return createTemplateButton ;
    }
}

function renderSignBox(signature:Nullable<SignatureDto>, isChecked: boolean, onCheckboxChange: () => void, callback:(action:Action, info?:TSDictionary)=>Promise<void>) {
    const qscdPhase = (signature?.phase !== SignaturePhase.Server) && config.app.qscd ;
    return (
        <SeaShellBox component="form" height="auto" mt="10">
            <HStack mt="unset" height="auto" display="flex" flexDirection={{ base: "column", sm: "row" }} justifyContent="space-between">
                <Checkbox isChecked={isChecked} size="lg" sx={{ '.chakra-checkbox__control': { bg: isChecked ? 'blue.500' : 'white' }}} onChange={onCheckboxChange}>
                    Je confirme avoir pris connaissance des documents à signer ainsi que des <Link component="form" href={config.app.cguURL} target="_blank">CGU</Link> et de la <Link component="form" href={config.app.privacyURL} target="_blank">politique de confidentialité</Link> de {config.app.title}
                </Checkbox>
                <Stack display="flex" width={{ base: "100%", sm: "unset" }} height="100%" flexDirection={{ base: "row", sm: "column" }} spacing={{ base: "unset", sm: "2" }} paddingTop={{ base: "2%", sm: "unset" }}>
                    <G1Button component="form" variant="normal" title={qscdPhase ? "Signer QSCD" : "Signer"} width="100px" fontSize="md" disabled={!isChecked} onClick={() => callback(Action.sign)} />
                    {qscdPhase ? <G1Button component="form" variant="important" title="Refuser" width="100px" fontSize="md" onClick={() => callback(Action.refuse)} /> : null}
                </Stack>
            </HStack>
        </SeaShellBox>
    ) ;
}

function renderSigners(signers: SignatureDto[], title: string) {
    return (
        <Box>
            <Text fontSize="medium" decoration="underline">{title}</Text>
            {signers.map((signature, i) => { 
                const refusalDate = $ok(signature.refusalDate) ? TSDate.from(signature.refusalDate) : signature.signatureDate ;
                const refusalReason =  $ok(signature.refusalReason) ? `pour le motif "${signature.refusalReason}"`: "" ;
                return i > 0 ?
                    (<><br/><Text key={`signer${i}`} fontSize="medium" as="b">{`${signature.signer.firstName} ${signature.signer.lastName} le ${refusalDate?.toLocaleString()} ${refusalReason}`}</Text></>)
                    :
                    (<Text key={`signer${i}`}fontSize="medium" as="b">{`${signature.signer.firstName} ${signature.signer.lastName} le ${refusalDate?.toLocaleString()} ${refusalReason}`}</Text>)
            })}
        </Box>
    ) ;
}

function renderFutureSigners(signers: SignatureDto[], title: string) {
    return (
        <Box>
            <Text fontSize="medium" decoration="underline">{title}</Text>
            {signers.map((signature, i) => { 
                return i > 0 ?
                    (<><br/><Text key={`futuresigner${i}`} fontSize="medium" as="b">{`${signature.signer.firstName} ${signature.signer.lastName}`}</Text></>)
                    :
                    (<Text key={`futuresigner${i}`} fontSize="medium" as="b">{`${signature.signer.firstName} ${signature.signer.lastName}`}</Text>)
            })}
        </Box>
    ) ;
}